/*
 * Soliguide: Useful information for those who need it
 *
 * SPDX-FileCopyrightText: © 2024 Solinum
 *
 * SPDX-License-Identifier: AGPL-3.0-only
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published
 * by the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <https://www.gnu.org/licenses/>.
 */
import { Categories } from "../enums";
import { CategoriesTreeNode, FlatCategoriesTreeNode } from "../interfaces";
import { CATEGORIES, CATEGORIES_ROOT_NODES } from "./CATEGORIES.const";

function categoriesFlatTreeToTree(
  rootNodes: FlatCategoriesTreeNode[]
): CategoriesTreeNode[] {
  const childrenToTree = (children: Categories[]): CategoriesTreeNode[] => {
    return children.map((child) => {
      const node = CATEGORIES.find((node) => child === node.id);
      return {
        id: child,
        children: node ? childrenToTree(node.children) : [],
      };
    });
  };

  return rootNodes.map((rootNode): CategoriesTreeNode => {
    return {
      id: rootNode.id,
      children: childrenToTree(rootNode.children),
    };
  });
}

export const CATEGORIES_TREE: CategoriesTreeNode[] = categoriesFlatTreeToTree(
  CATEGORIES_ROOT_NODES
);
