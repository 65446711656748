/*
 * Soliguide: Useful information for those who need it
 *
 * SPDX-FileCopyrightText: © 2024 Solinum
 *
 * SPDX-License-Identifier: AGPL-3.0-only
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published
 * by the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <https://www.gnu.org/licenses/>.
 */
// https://fr.wikipedia.org/wiki/Liste_des_d%C3%A9partements_fran%C3%A7ais

import { CountryCodes } from "../../enums";
import { RegionDef } from "../../interfaces";

export const FR_REGIONS_DEF: Array<RegionDef<CountryCodes.FR>> = [
  {
    regionCode: "01",
    regionName: "Guadeloupe",
    slug: "guadeloupe",
    timeZone: "America/Martinique",
    departments: [
      {
        departmentCode: "971",
        departmentName: "Guadeloupe",
        coordinates: [-61.487996, 16.206931],
      },
    ],
    coordinates: [-61.487996, 16.206931],
  },
  {
    regionCode: "02",
    regionName: "Martinique",
    slug: "martinique",
    timeZone: "America/Martinique",
    departments: [
      {
        departmentCode: "972",
        departmentName: "Martinique",
        coordinates: [-60.94789, 14.602637],
      },
    ],
    coordinates: [-60.94789, 14.602637],
  },
  {
    regionCode: "03",
    regionName: "Guyane",
    slug: "guyane",
    timeZone: "America/Cayenne",
    departments: [
      {
        departmentCode: "973",
        departmentName: "Guyane",
        coordinates: [-53.183642, 3.720608],
      },
    ],
    coordinates: [-53.183642, 3.720608],
  },
  {
    regionCode: "04",
    regionName: "La Réunion",
    slug: "la-reunion",
    timeZone: "Indian/Reunion",
    departments: [
      {
        departmentCode: "974",
        departmentName: "La Réunion",
        coordinates: [55.513348, -21.164501],
      },
    ],
    coordinates: [55.513348, -21.164501],
  },
  {
    regionCode: "06",
    regionName: "Mayotte",
    slug: "mayotte",
    timeZone: "Indian/Mayotte",
    departments: [
      {
        departmentCode: "976",
        departmentName: "Mayotte",
        coordinates: [45.155103, -12.814881],
      },
    ],
    coordinates: [45.155103, -12.814881],
  },
  {
    regionCode: "11",
    regionName: "Île-de-France",
    timeZone: "Europe/Paris",
    slug: "ile-de-france",
    departments: [
      {
        departmentCode: "75",
        departmentName: "Paris",
        coordinates: [2.362203, 48.846223],
      },
      {
        departmentCode: "77",
        departmentName: "Seine-et-Marne",
        coordinates: [2.949703, 48.64091],
      },
      {
        departmentCode: "78",
        departmentName: "Yvelines",
        coordinates: [1.828046, 48.792871],
      },
      {
        departmentCode: "91",
        departmentName: "Essonne",
        coordinates: [2.238797, 48.531332],
      },
      {
        departmentCode: "92",
        departmentName: "Hauts-de-Seine",
        coordinates: [2.250681, 48.814825],
      },
      {
        departmentCode: "93",
        departmentName: "Seine-Saint-Denis",
        coordinates: [2.502605, 48.923555],
      },
      {
        departmentCode: "94",
        departmentName: "Val-de-Marne",
        coordinates: [2.479868, 48.770561],
      },
      {
        departmentCode: "95",
        departmentName: "Val-d'Oise",
        coordinates: [2.083741, 49.102974],
      },
    ],
    coordinates: [2.496865, 48.730534],
  },
  {
    regionCode: "24",
    regionName: "Centre-Val de Loire",
    timeZone: "Europe/Paris",
    slug: "centre-val-de-loire",
    departments: [
      {
        departmentCode: "18",
        departmentName: "Cher",
        coordinates: [2.394086, 46.993659],
      },
      {
        departmentCode: "28",
        departmentName: "Eure-et-Loir",
        coordinates: [1.356137, 48.409707],
      },
      {
        departmentCode: "36",
        departmentName: "Indre",
        coordinates: [1.590607, 46.719993],
      },
      {
        departmentCode: "37",
        departmentName: "Indre-et-Loire",
        coordinates: [0.668143, 47.274559],
      },
      {
        departmentCode: "41",
        departmentName: "Loir-et-Cher",
        coordinates: [1.333241, 47.697403],
      },
      {
        departmentCode: "45",
        departmentName: "Loiret",
        coordinates: [2.345241, 47.931968],
      },
    ],
    coordinates: [1.571313, 47.635239],
  },
  {
    regionCode: "27",
    regionName: "Bourgogne-Franche-Comté",
    timeZone: "Europe/Paris",
    slug: "bourgogne-franche-comte",
    departments: [
      {
        departmentCode: "21",
        departmentName: "Côte-d'Or",
        coordinates: [4.749979, 47.412075],
      },
      {
        departmentCode: "25",
        departmentName: "Doubs",
        coordinates: [6.307457, 47.217272],
      },
      {
        departmentCode: "39",
        departmentName: "Jura",
        coordinates: [5.591728, 46.79907],
      },
      {
        departmentCode: "58",
        departmentName: "Nièvre",
        coordinates: [3.681821, 47.137335],
      },
      {
        departmentCode: "70",
        departmentName: "Haute-Saône",
        coordinates: [6.012871, 47.590818],
      },
      {
        departmentCode: "71",
        departmentName: "Saône-et-Loire",
        coordinates: [4.619501, 46.678941],
      },
      {
        departmentCode: "89",
        departmentName: "Yonne",
        coordinates: [3.626542, 47.810206],
      },
      {
        departmentCode: "90",
        departmentName: "Territoire de Belfort",
        coordinates: [6.947931, 47.606837],
      },
    ],
    coordinates: [4.830839, 47.316236],
  },
  {
    regionCode: "28",
    regionName: "Normandie",
    timeZone: "Europe/Paris",
    slug: "normandie",
    departments: [
      {
        departmentCode: "14",
        departmentName: "Calvados",
        coordinates: [-0.433769, 49.053979],
      },
      {
        departmentCode: "27",
        departmentName: "Eure",
        coordinates: [1.063561, 49.148454],
      },
      {
        departmentCode: "50",
        departmentName: "Manche",
        coordinates: [-1.414142, 49.116465],
      },
      {
        departmentCode: "61",
        departmentName: "Orne",
        coordinates: [0.034851, 48.583381],
      },
      {
        departmentCode: "76",
        departmentName: "Seine-Maritime",
        coordinates: [1.156989, 49.590326],
      },
    ],
    coordinates: [-0.245012, 49.09545],
  },
  {
    regionCode: "32",
    regionName: "Hauts-de-France",
    timeZone: "Europe/Paris",
    slug: "hauts-de-france",
    departments: [
      {
        departmentCode: "02",
        departmentName: "Aisne",
        coordinates: [3.45097, 49.479355],
      },
      {
        departmentCode: "59",
        departmentName: "Nord",
        coordinates: [3.20406, 50.434006],
      },
      {
        departmentCode: "60",
        departmentName: "Oise",
        coordinates: [2.50564, 49.399018],
      },
      {
        departmentCode: "62",
        departmentName: "Pas-de-Calais",
        coordinates: [2.497439, 50.381139],
      },
      {
        departmentCode: "80",
        departmentName: "Somme",
        coordinates: [2.389903, 49.96157],
      },
    ],
    coordinates: [2.816916, 49.868958],
  },
  {
    regionCode: "44",
    regionName: "Grand Est",
    slug: "grand-est",
    timeZone: "Europe/Paris",
    departments: [
      {
        departmentCode: "08",
        departmentName: "Ardennes",
        coordinates: [4.709835, 49.634965],
      },
      {
        departmentCode: "10",
        departmentName: "Aube",
        coordinates: [4.089277, 48.317772],
      },
      {
        departmentCode: "51",
        departmentName: "Marne",
        coordinates: [4.248071, 48.904449],
      },
      {
        departmentCode: "52",
        departmentName: "Haute-Marne",
        coordinates: [5.212101, 48.112613],
      },
      {
        departmentCode: "54",
        departmentName: "Meurthe-et-Moselle",
        coordinates: [6.10537, 48.887503],
      },
      {
        departmentCode: "55",
        departmentName: "Meuse",
        coordinates: [5.396658, 49.059767],
      },
      {
        departmentCode: "57",
        departmentName: "Moselle",
        coordinates: [6.660133, 49.044461],
      },
      {
        departmentCode: "67",
        departmentName: "Bas-Rhin",
        coordinates: [7.415949, 48.764522],
      },
      {
        departmentCode: "68",
        departmentName: "Haut-Rhin",
        coordinates: [7.201035, 47.817528],
      },
      {
        departmentCode: "88",
        departmentName: "Vosges",
        coordinates: [6.304189, 48.233694],
      },
    ],
    coordinates: [5.416189, 48.705959],
  },
  {
    regionCode: "52",
    regionName: "Pays de la Loire",
    slug: "pays-de-la-loire",
    timeZone: "Europe/Paris",
    departments: [
      {
        departmentCode: "44",
        departmentName: "Loire-Atlantique",
        coordinates: [-1.734926, 47.301465],
      },
      {
        departmentCode: "49",
        departmentName: "Maine-et-Loire",
        coordinates: [-0.678045, 47.384117],
      },
      {
        departmentCode: "53",
        departmentName: "Mayenne",
        coordinates: [-0.660454, 48.162685],
      },
      {
        departmentCode: "72",
        departmentName: "Sarthe",
        coordinates: [0.235599, 48.011005],
      },
      {
        departmentCode: "85",
        departmentName: "Vendée",
        coordinates: [-1.422402, 46.728358],
      },
    ],
    coordinates: [-0.974108, 47.425382],
  },
  {
    regionCode: "53",
    regionName: "Bretagne",
    slug: "bretagne",
    timeZone: "Europe/Paris",
    departments: [
      {
        departmentCode: "22",
        departmentName: "Côtes-d'armor",
        coordinates: [-2.943113, 48.596217],
      },
      {
        departmentCode: "29",
        departmentName: "Finistère",
        coordinates: [-4.30232, 48.244427],
      },
      {
        departmentCode: "35",
        departmentName: "Ille-et-Vilaine",
        coordinates: [-1.777276, 48.280444],
      },
      {
        departmentCode: "56",
        departmentName: "Morbihan",
        coordinates: [-2.984379, 47.627768],
      },
    ],
    coordinates: [-3.477866, 48.144871],
  },
  {
    regionCode: "75",
    regionName: "Nouvelle-Aquitaine",
    slug: "nouvelle-aquitaine",
    timeZone: "Europe/Paris",
    departments: [
      {
        departmentCode: "16",
        departmentName: "Charente",
        coordinates: [0.262957, 45.741113],
      },
      {
        departmentCode: "17",
        departmentName: "Charente-Maritime",
        coordinates: [-0.793718, 45.832494],
      },
      {
        departmentCode: "19",
        departmentName: "Corrèze",
        coordinates: [1.901582, 45.377903],
      },
      {
        departmentCode: "23",
        departmentName: "Creuse",
        coordinates: [1.915202, 46.071873],
      },
      {
        departmentCode: "24",
        departmentName: "Dordogne",
        coordinates: [0.745748, 45.13781],
      },
      {
        departmentCode: "33",
        departmentName: "Gironde",
        coordinates: [-0.508861, 44.767449],
      },
      {
        departmentCode: "40",
        departmentName: "Landes",
        coordinates: [-0.585044, 43.812626],
      },
      {
        departmentCode: "47",
        departmentName: "Lot-et-Garonne",
        coordinates: [0.504368, 44.38994],
      },
      {
        departmentCode: "64",
        departmentName: "Pyrénées-Atlantiques",
        coordinates: [-0.777902, 43.293375],
      },
      {
        departmentCode: "79",
        departmentName: "Deux-Sèvres",
        coordinates: [-0.361194, 46.589563],
      },
      {
        departmentCode: "86",
        departmentName: "Vienne",
        coordinates: [0.428883, 46.532391],
      },
      {
        departmentCode: "87",
        departmentName: "Haute-Vienne",
        coordinates: [1.2687, 45.92186],
      },
    ],
    coordinates: [0.078322, 45.159216],
  },
  {
    regionCode: "76",
    regionName: "Occitanie",
    slug: "occitanie",
    timeZone: "Europe/Paris",
    departments: [
      {
        departmentCode: "09",
        departmentName: "Ariège",
        coordinates: [1.503476, 42.956535],
      },
      {
        departmentCode: "11",
        departmentName: "Aude",
        coordinates: [2.338232, 43.14276],
      },
      {
        departmentCode: "12",
        departmentName: "Aveyron",
        coordinates: [2.631496, 44.308805],
      },
      {
        departmentCode: "30",
        departmentName: "Gard",
        coordinates: [4.004137, 43.960422],
      },
      {
        departmentCode: "31",
        departmentName: "Haute-Garonne",
        coordinates: [1.182843, 43.34134],
      },
      {
        departmentCode: "32",
        departmentName: "Gers",
        coordinates: [0.447546, 43.720332],
      },
      {
        departmentCode: "34",
        departmentName: "Hérault",
        coordinates: [3.341569, 43.558195],
      },
      {
        departmentCode: "46",
        departmentName: "Lot",
        coordinates: [1.616189, 44.607526],
      },
      {
        departmentCode: "48",
        departmentName: "Lozère",
        coordinates: [3.547536, 44.53028],
      },
      {
        departmentCode: "65",
        departmentName: "Hautes-Pyrénées",
        coordinates: [0.14542, 43.156191],
      },
      {
        departmentCode: "66",
        departmentName: "Pyrénées-Orientales",
        coordinates: [2.59005, 42.542448],
      },
      {
        departmentCode: "81",
        departmentName: "Tarn",
        coordinates: [2.173337, 43.798605],
      },
      {
        departmentCode: "82",
        departmentName: "Tarn-et-Garonne",
        coordinates: [1.290744, 44.116487],
      },
    ],
    coordinates: [2.102571, 43.74493],
  },
  {
    regionCode: "84",
    regionName: "Auvergne-Rhône-Alpes",
    slug: "auvergne-rhone-alpes",
    timeZone: "Europe/Paris",
    departments: [
      {
        departmentCode: "01",
        departmentName: "Ain",
        coordinates: [5.468741, 46.173123],
      },
      {
        departmentCode: "03",
        departmentName: "Allier",
        coordinates: [3.111639, 46.360089],
      },
      {
        departmentCode: "07",
        departmentName: "Ardèche",
        coordinates: [4.314696, 44.775981],
      },
      {
        departmentCode: "15",
        departmentName: "Cantal",
        coordinates: [2.738229, 45.032211],
      },
      {
        departmentCode: "26",
        departmentName: "Drôme",
        coordinates: [5.253157, 44.623857],
      },
      {
        departmentCode: "38",
        departmentName: "Isère",
        coordinates: [5.599029, 45.261772],
      },
      {
        departmentCode: "42",
        departmentName: "Loire",
        coordinates: [4.197503, 45.703368],
      },
      {
        departmentCode: "43",
        departmentName: "Haute-Loire",
        coordinates: [3.758202, 45.158436],
      },
      {
        departmentCode: "63",
        departmentName: "Puy-de-Dôme",
        coordinates: [3.118858, 45.708741],
      },
      {
        departmentCode: "69",
        departmentName: "Rhône",
        coordinates: [4.623249, 45.889921],
      },
      {
        departmentCode: "73",
        departmentName: "Savoie",
        coordinates: [6.408613, 45.49486],
      },
      {
        departmentCode: "74",
        departmentName: "Haute-Savoie",
        coordinates: [6.447215, 46.023091],
      },
    ],
    coordinates: [4.4029, 45.482715],
  },
  {
    regionCode: "93",
    regionName: "Provence-Alpes-Côte d'Azur",
    slug: "paca",
    timeZone: "Europe/Paris",
    departments: [
      {
        departmentCode: "04",
        departmentName: "Alpes-de-Haute-Provence",
        coordinates: [6.346072, 44.152901],
      },
      {
        departmentCode: "05",
        departmentName: "Hautes-Alpes",
        coordinates: [6.22342, 44.657922],
      },
      {
        departmentCode: "06",
        departmentName: "Alpes-Maritimes",
        coordinates: [7.1138, 43.781238],
      },
      {
        departmentCode: "13",
        departmentName: "Bouches-du-Rhône",
        coordinates: [5.126066, 43.37314],
      },
      {
        departmentCode: "83",
        departmentName: "Var",
        coordinates: [6.356999, 43.223493],
      },
      {
        departmentCode: "84",
        departmentName: "Vaucluse",
        coordinates: [5.153495, 44.128911],
      },
    ],
    coordinates: [6.085947, 43.528682],
  },
  {
    regionCode: "94",
    slug: "corse",
    regionName: "Corse",
    timeZone: "Europe/Paris",
    departments: [
      {
        departmentCode: "2A",
        departmentName: "Corse-du-Sud",
        coordinates: [8.925533, 41.813641],
      },
      {
        departmentCode: "2B",
        departmentName: "Haute-Corse",
        coordinates: [9.098208, 42.561055],
      },
    ],
    coordinates: [8.976631, 42.074408],
  },
  {
    regionCode: "NC",
    slug: "nouvelle-caledonie",
    regionName: "Nouvelle Calédonie",
    timeZone: "Pacific/Noumea",
    departments: [
      {
        departmentCode: "988",
        departmentName: "Nouvelle Calédonie",
        coordinates: [0.078322, 45.159216],
      },
    ],
    coordinates: [0.078322, 45.159216],
  },
  {
    regionCode: "PO",
    slug: "polynesie-française",
    regionName: "Polynésie Française",
    coordinates: [-149.3667436, -17.650195245],

    timeZone: "Pacific/Tahiti",
    departments: [
      {
        departmentCode: "987",
        departmentName: "Polynésie Française",
        coordinates: [-149.3667436, -17.650195245],
      },
    ],
  },
  {
    regionCode: "SB",
    slug: "saint-barthelemy",
    timeZone: "America/Martinique",
    regionName: "Saint-Barthélemy",
    coordinates: [0.235599, 48.011005],
    departments: [
      {
        departmentCode: "977",
        departmentName: "Saint-Barthélemy",
        coordinates: [0.235599, 48.011005],
      },
    ],
  },
  {
    regionCode: "SM",
    slug: "saint-martin",
    regionName: "Saint-Martin",
    timeZone: "America/Martinique",
    departments: [
      {
        departmentCode: "978",
        departmentName: "Saint-Martin",
        coordinates: [-60.94789, 14.602637],
      },
    ],
    coordinates: [-60.94789, 14.602637],
  },
  {
    regionCode: "SP",
    slug: "saint-pierre-et-miquelon",
    regionName: "Saint-Pierre-et-Miquelon",
    timeZone: "America/Miquelon",
    coordinates: [4.619501, 46.678941],
    departments: [
      {
        departmentCode: "975",
        departmentName: "Saint-Pierre-et-Miquelon",
        coordinates: [4.619501, 46.678941],
      },
    ],
  },
  {
    regionCode: "WF",
    slug: "wallis-et-futuna",
    regionName: "Wallis-et-Futuna",
    timeZone: "Pacific/Wallis",
    coordinates: [-178.1202846, -14.3230751],
    departments: [
      {
        departmentCode: "986",
        departmentName: "Wallis-et-Futuna",
        coordinates: [-178.1202846, -14.3230751],
      },
    ],
  },
  {
    regionCode: "TF",
    slug: "terres-australes-francaises",
    regionName: "Terres australes françaises",
    timeZone: "Indian/Maldives",
    coordinates: [69.15949177338582, -49.253692233007015],
    departments: [
      {
        departmentCode: "984",
        departmentName: "Terres australes françaises",
        coordinates: [69.15949177338582, -49.253692233007015],
      },
    ],
  },
];
