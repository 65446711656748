/*
 * Soliguide: Useful information for those who need it
 *
 * SPDX-FileCopyrightText: © 2024 Solinum
 *
 * SPDX-License-Identifier: AGPL-3.0-only
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published
 * by the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <https://www.gnu.org/licenses/>.
 */
export enum Categories {
  HEALTH = "health",
  ADDICTION = "addiction",
  STD_TESTING = "std_testing",
  PSYCHOLOGICAL_SUPPORT = "psychological_support",
  CHILD_CARE = "child_care",
  GENERAL_PRACTITIONER = "general_practitioner",
  DENTAL_CARE = "dental_care",
  PREGNANCY_CARE = "pregnancy_care",
  VACCINATION = "vaccination",
  INFIRMARY = "infirmary",
  VET_CARE = "vet_care",
  HEALTH_SPECIALISTS = "health_specialists",
  ALLERGOLOGY = "allergology",
  CARDIOLOGY = "cardiology",
  DERMATOLOGY = "dermatology",
  ECHOGRAPHY = "echography",
  ENDOCRINOLOGY = "endocrinology",
  GASTROENTEROLOGY = "gastroenterology",
  GYNECOLOGY = "gynecology",
  KINESITHERAPY = "kinesitherapy",
  MAMMOGRAPHY = "mammography",
  OPHTHALMOLOGY = "ophthalmology",
  OTORHINOLARYNGOLOGY = "otorhinolaryngology",
  NUTRITION = "nutrition",
  PEDICURE = "pedicure",
  PHLEBOLOGY = "phlebology",
  PNEUMOLOGY = "pneumology",
  RADIOLOGY = "radiology",
  RHEUMATOLOGY = "rheumatology",
  UROLOGY = "urology",
  SPEECH_THERAPY = "speech_therapy",
  STOMATOLOGY = "stomatology",
  OSTEOPATHY = "osteopathy",
  ACUPUNCTURE = "acupuncture",
  MOBILITY = "mobility",
  CARPOOLING = "carpooling",
  PROVISION_OF_VEHICLES = "provision_of_vehicles",
  CHAUFFEUR_DRIVEN_TRANSPORT = "chauffeur_driven_transport",
  MOBILITY_ASSISTANCE = "mobility_assistance",
  TRAINING_AND_JOBS = "training_and_jobs",
  DIGITAL_TOOLS_TRAINING = "digital_tools_training",
  FRENCH_COURSE = "french_course",
  JOB_COACHING = "job_coaching",
  INTEGRATION_THROUGH_ECONOMIC_ACTIVITY = "integration_through_economic_activity",
  TUTORING = "tutoring",
  HYGIENE_AND_WELLNESS = "hygiene_and_wellness",
  SHOWER = "shower",
  LAUNDRY = "laundry",
  WELLNESS = "wellness",
  TOILETS = "toilets",
  HYGIENE_PRODUCTS = "hygiene_products",
  FACE_MASKS = "face_masks",
  COUNSELING = "counseling",
  LEGAL_ADVICE = "legal_advice",
  DOMICILIATION = "domiciliation",
  SOCIAL_ACCOMPANIMENT = "social_accompaniment",
  PUBLIC_WRITER = "public_writer",
  DISABILITY_ADVICE = "disability_advice",
  ADMINISTRATIVE_ASSISTANCE = "administrative_assistance",
  PARENT_ASSISTANCE = "parent_assistance",
  BUDGET_ADVICE = "budget_advice",
  TECHNOLOGY = "technology",
  COMPUTERS_AT_YOUR_DISPOSAL = "computers_at_your_disposal",
  WIFI = "wifi",
  ELECTRICAL_OUTLETS_AVAILABLE = "electrical_outlets_available",
  TELEPHONE_AT_YOUR_DISPOSAL = "telephone_at_your_disposal",
  DIGITAL_SAFE = "digital_safe",
  FOOD = "food",
  FOOD_DISTRIBUTION = "food_distribution",
  FOOD_PACKAGES = "food_packages",
  SOCIAL_GROCERY_STORES = "social_grocery_stores",
  FOUNTAIN = "fountain",
  SOLIDARITY_FRIDGE = "solidarity_fridge",
  SHARED_KITCHEN = "shared_kitchen",
  COOKING_WORKSHOP = "cooking_workshop",
  BABY_PARCEL = "baby_parcel",
  FOOD_VOUCHER = "food_voucher",
  COMMUNITY_GARDEN = "community_garden",
  WELCOME = "welcome",
  DAY_HOSTING = "day_hosting",
  REST_AREA = "rest_area",
  BABYSITTING = "babysitting",
  FAMILY_AREA = "family_area",
  INFORMATION_POINT = "information_point",
  ACTIVITIES = "activities",
  SPORT_ACTIVITIES = "sport_activities",
  MUSEUMS = "museums",
  LIBRARIES = "libraries",
  OTHER_ACTIVITIES = "other_activities",
  EQUIPMENT = "equipment",
  LUGGAGE_STORAGE = "luggage_storage",
  SOLIDARITY_STORE = "solidarity_store",
  CLOTHING = "clothing",
  ANIMAL_ASSITANCE = "animal_assitance",
  ACCOMODATION_AND_HOUSING = "accomodation_and_housing",
  OVERNIGHT_STOP = "overnight_stop",
  EMERGENCY_ACCOMMODATION = "emergency_accommodation",
  LONG_TERM_ACCOMODATION = "long_term_accomodation",
  CITIZEN_HOUSING = "citizen_housing",
  ACCESS_TO_HOUSING = "access_to_housing",
}
