/*
 * Soliguide: Useful information for those who need it
 *
 * SPDX-FileCopyrightText: © 2024 Solinum
 *
 * SPDX-License-Identifier: AGPL-3.0-only
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published
 * by the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <https://www.gnu.org/licenses/>.
 */
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

import {
  faLocationDot,
  faMagnifyingGlass,
  faMapPin,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";

import { NgbTypeaheadSelectItemEvent } from "@ng-bootstrap/ng-bootstrap";

import {
  GeoApiGouvAddress,
  GeoApiGouvAddressFeatureCollection,
  GeoApiGouvAddressResponse,
  GeoApiGouvAddressService,
} from "@placeme/ngx-geo-api-gouv-address";

import {
  FR_BOROUGHS,
  FR_BOROUGHS_LYON,
  FR_BOROUGHS_MARSEILLE,
  FR_BOROUGHS_PARIS,
  GeoTypes,
  slugLocation,
} from "@soliguide/common";

import { Point } from "geojson";

import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";

import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
  Observable,
  of,
  OperatorFunction,
  switchMap,
  tap,
} from "rxjs";

import { Search } from "../../../../models";

import { globalConstants } from "../../../../shared";
import { PosthogService } from "../../../analytics/services/posthog.service";

@Component({
  selector: "app-autocomplete-location",
  templateUrl: "./autocomplete-location.component.html",
  styleUrls: ["./autocomplete-location.component.scss"],
})
export class AutocompleteLocationComponent implements OnInit {
  @Input() public search!: Search;

  @Output()
  public updateLocation = new EventEmitter<void>();

  public searching = false;
  public geolocateLoading = false;
  public searchFailed = false;

  public readonly faSpinner = faSpinner;
  public readonly faMapPin = faMapPin;
  public readonly faLocationDot = faLocationDot;
  public readonly faMagnifyingGlass = faMagnifyingGlass;

  public currentLocationExist: boolean;

  constructor(
    private readonly geoApiGouvAddressService: GeoApiGouvAddressService,
    private readonly toastr: ToastrService,
    private readonly posthogService: PosthogService,
    private readonly translateService: TranslateService
  ) {
    this.currentLocationExist = false;
  }

  public ngOnInit(): void {
    this.refreshCurrentLocation();
  }

  public locationAutocomplete: OperatorFunction<
    string,
    readonly GeoApiGouvAddressFeatureCollection[]
  > = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => (this.searching = true)),
      switchMap((term: string) =>
        this.getAndFormatAdress(term).pipe(
          tap(() => (this.searchFailed = false)),
          catchError(() => {
            this.searchFailed = true;
            return of([]);
          })
        )
      ),
      tap(() => (this.searching = false))
    );

  public formatter = (x: GeoApiGouvAddress) => x.label;

  public getAndFormatAdress(
    term: string
  ): Observable<GeoApiGouvAddressFeatureCollection[]> {
    return this.geoApiGouvAddressService.query({ q: term }).pipe(
      map((geoApiGouvAddressResponse: GeoApiGouvAddressResponse) => {
        return geoApiGouvAddressResponse.features.reduce(
          (
            acc: GeoApiGouvAddressFeatureCollection[],
            result: GeoApiGouvAddressFeatureCollection
          ) => {
            acc.push(result);
            return acc;
          },
          []
        );
      })
    );
  }

  public getLocation = (): void => {
    this.searching = true;
    this.geolocateLoading = true;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position: GeolocationPosition) => {
          await this.posthogService.capture("search-around-me", {
            position,
          });

          this.getAddresseFromLocation(position.coords);
        },
        (error: GeolocationPositionError) => {
          this.geolocateLoading = false;
          switch (error.code) {
            case error.TIMEOUT:
              this.toastr.error(
                this.translateService.instant("UNABLE_TO_DETERMINE_POSITION")
              );
              break;
            case error.PERMISSION_DENIED:
              this.toastr.error(
                this.translateService.instant("UNAUTHORIZED_LOCATION")
              );
              break;
            default:
              this.toastr.error(
                this.translateService.instant("UNABLE_TO_DETERMINE_POSITION")
              );
          }
          this.searching = false;
        },
        {
          timeout: 5000,
          maximumAge: 60000,
          enableHighAccuracy: true,
        }
      );
    } else {
      this.searching = false;
      this.toastr.error(this.translateService.instant("UNABLE_TO_LOCATE_YOU"));
    }
  };

  public getAddresseFromLocation(coords: GeolocationCoordinates) {
    const longitude = coords.longitude;
    const latitude = coords.latitude;

    this.geoApiGouvAddressService
      .reverse({ lat: latitude, lon: longitude })
      .subscribe({
        next: (geoApiGouvAddressResponse: GeoApiGouvAddressResponse) => {
          this.searching = false;
          this.geolocateLoading = false;
          if (geoApiGouvAddressResponse.features.length > 0) {
            this.updateSearchLocation(geoApiGouvAddressResponse.features[0]);
          } else {
            this.toastr.error(
              this.translateService.instant("UNABLE_TO_DETERMINE_POSITION")
            );
          }
        },
        error: () => {
          this.searching = false;
          this.toastr.error(
            this.translateService.instant("UNABLE_TO_DETERMINE_POSITION")
          );
        },
      });
  }

  public selectItem(event: NgbTypeaheadSelectItemEvent) {
    this.updateSearchLocation(event.item as GeoApiGouvAddressFeatureCollection);
  }

  public refreshCurrentLocation() {
    const currentLocation = globalConstants.getItem("currentLocation");
    if (!currentLocation) {
      globalConstants.setItem("currentLocation", this.search.location);
    } else {
      this.currentLocationExist = true;
    }
  }

  // Mise à jour de l'adresse dans la recherche
  public updateSearchLocation(address: GeoApiGouvAddressFeatureCollection) {
    const geometry = address.geometry as Point;

    if (address) {
      if (address.properties.type === "municipality") {
        this.search.location.geoType = GeoTypes.CITY;
        this.search.location.geoValue = slugLocation(address.properties.city);

        if (FR_BOROUGHS.includes(address.properties.postcode)) {
          if (FR_BOROUGHS_LYON.includes(address.properties.postcode)) {
            this.search.location.geoValue = "lyon";
          }
          if (FR_BOROUGHS_MARSEILLE.includes(address.properties.postcode)) {
            this.search.location.geoValue = "marseille";
          }

          if (FR_BOROUGHS_PARIS.includes(address.properties.postcode)) {
            this.search.location.geoValue = "paris";
          }
        }
      } else {
        this.search.location.geoType = GeoTypes.POSITION;
        this.search.location.geoValue = slugLocation(address.properties.label);
      }

      this.search.location.coordinates = [
        geometry.coordinates[0],
        geometry.coordinates[1],
      ];
      this.search.location.label = address.properties.label;

      globalConstants.setItem("currentLocation", this.search.location);

      this.currentLocationExist = true;
      this.updateLocation.emit();
    } else {
      this.toastr.error(
        this.translateService.instant("UNABLE_TO_DETERMINE_POSITION")
      );
    }
  }
}
