/*
 * Soliguide: Useful information for those who need it
 *
 * SPDX-FileCopyrightText: © 2024 Solinum
 *
 * SPDX-License-Identifier: AGPL-3.0-only
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published
 * by the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <https://www.gnu.org/licenses/>.
 */
import { Categories } from "../enums";
import { FlatCategoriesTreeNode } from "../interfaces";

export const CATEGORIES: FlatCategoriesTreeNode[] = [
  {
    id: Categories.WELCOME,
    children: [
      Categories.DAY_HOSTING,
      Categories.REST_AREA,
      Categories.BABYSITTING,
      Categories.FAMILY_AREA,
      Categories.INFORMATION_POINT,
    ],
  },
  {
    id: Categories.DAY_HOSTING,
    children: [],
  },
  {
    id: Categories.REST_AREA,
    children: [],
  },
  {
    id: Categories.BABYSITTING,
    children: [],
  },
  {
    id: Categories.FAMILY_AREA,
    children: [],
  },
  {
    id: Categories.INFORMATION_POINT,
    children: [],
  },
  {
    id: Categories.ACTIVITIES,
    children: [
      Categories.SPORT_ACTIVITIES,
      Categories.MUSEUMS,
      Categories.LIBRARIES,
      Categories.OTHER_ACTIVITIES,
    ],
  },
  {
    id: Categories.SPORT_ACTIVITIES,
    children: [],
  },
  {
    id: Categories.MUSEUMS,
    children: [],
  },
  {
    id: Categories.LIBRARIES,
    children: [],
  },
  {
    id: Categories.OTHER_ACTIVITIES,
    children: [],
  },
  {
    id: Categories.FOOD,
    children: [
      Categories.FOOD_DISTRIBUTION,
      Categories.FOOD_PACKAGES,
      Categories.BABY_PARCEL,
      Categories.FOOD_VOUCHER,
      Categories.SOCIAL_GROCERY_STORES,
      Categories.FOUNTAIN,
      Categories.SHARED_KITCHEN,
      Categories.COOKING_WORKSHOP,
      Categories.COMMUNITY_GARDEN,
      Categories.SOLIDARITY_FRIDGE,
    ],
  },
  {
    id: Categories.FOOD_DISTRIBUTION,
    children: [],
  },
  {
    id: Categories.FOOD_PACKAGES,
    children: [],
  },
  {
    id: Categories.SOCIAL_GROCERY_STORES,
    children: [],
  },
  {
    id: Categories.FOUNTAIN,
    children: [],
  },
  { id: Categories.SOLIDARITY_FRIDGE, children: [] },
  { id: Categories.SHARED_KITCHEN, children: [] },
  {
    id: Categories.COOKING_WORKSHOP,
    children: [],
  },
  {
    id: Categories.BABY_PARCEL,
    children: [],
  },
  {
    id: Categories.FOOD_VOUCHER,
    children: [],
  },
  {
    id: Categories.COMMUNITY_GARDEN,
    children: [],
  },
  {
    id: Categories.COUNSELING,
    children: [
      Categories.LEGAL_ADVICE,
      Categories.DOMICILIATION,
      Categories.SOCIAL_ACCOMPANIMENT,
      Categories.PUBLIC_WRITER,
      Categories.DISABILITY_ADVICE,
      Categories.ADMINISTRATIVE_ASSISTANCE,
      Categories.PARENT_ASSISTANCE,
      Categories.BUDGET_ADVICE,
    ],
  },
  {
    id: Categories.LEGAL_ADVICE,
    children: [],
  },
  {
    id: Categories.DOMICILIATION,
    children: [],
  },
  {
    id: Categories.SOCIAL_ACCOMPANIMENT,
    children: [],
  },
  {
    id: Categories.PUBLIC_WRITER,
    children: [],
  },
  {
    id: Categories.DISABILITY_ADVICE,
    children: [],
  },
  {
    id: Categories.ADMINISTRATIVE_ASSISTANCE,
    children: [],
  },
  {
    id: Categories.PARENT_ASSISTANCE,
    children: [],
  },
  {
    id: Categories.BUDGET_ADVICE,
    children: [],
  },
  {
    id: Categories.TRAINING_AND_JOBS,
    children: [
      Categories.DIGITAL_TOOLS_TRAINING,
      Categories.FRENCH_COURSE,
      Categories.JOB_COACHING,
      Categories.INTEGRATION_THROUGH_ECONOMIC_ACTIVITY,
      Categories.TUTORING,
    ],
  },
  {
    id: Categories.DIGITAL_TOOLS_TRAINING,
    children: [],
  },
  {
    id: Categories.FRENCH_COURSE,
    children: [],
  },
  {
    id: Categories.JOB_COACHING,
    children: [],
  },
  {
    id: Categories.INTEGRATION_THROUGH_ECONOMIC_ACTIVITY,
    children: [],
  },
  {
    id: Categories.TUTORING,
    children: [],
  },
  {
    id: Categories.ACCOMODATION_AND_HOUSING,
    children: [
      Categories.OVERNIGHT_STOP,
      Categories.EMERGENCY_ACCOMMODATION,
      Categories.LONG_TERM_ACCOMODATION,
      Categories.CITIZEN_HOUSING,
      Categories.ACCESS_TO_HOUSING,
    ],
  },
  {
    id: Categories.OVERNIGHT_STOP,
    children: [],
  },
  {
    id: Categories.EMERGENCY_ACCOMMODATION,
    children: [],
  },
  {
    id: Categories.LONG_TERM_ACCOMODATION,
    children: [],
  },
  {
    id: Categories.CITIZEN_HOUSING,
    children: [],
  },
  {
    id: Categories.ACCESS_TO_HOUSING,
    children: [],
  },
  {
    id: Categories.HYGIENE_AND_WELLNESS,
    children: [
      Categories.SHOWER,
      Categories.LAUNDRY,
      Categories.WELLNESS,
      Categories.TOILETS,
      Categories.HYGIENE_PRODUCTS,
      Categories.FACE_MASKS,
    ],
  },
  {
    id: Categories.SHOWER,
    children: [],
  },
  {
    id: Categories.LAUNDRY,
    children: [],
  },
  {
    id: Categories.WELLNESS,
    children: [],
  },
  {
    id: Categories.TOILETS,
    children: [],
  },
  {
    id: Categories.HYGIENE_PRODUCTS,
    children: [],
  },
  {
    id: Categories.FACE_MASKS,
    children: [],
  },
  {
    id: Categories.EQUIPMENT,
    children: [
      Categories.LUGGAGE_STORAGE,
      Categories.SOLIDARITY_STORE,
      Categories.CLOTHING,
      Categories.ANIMAL_ASSITANCE,
    ],
  },
  {
    id: Categories.LUGGAGE_STORAGE,
    children: [],
  },
  {
    id: Categories.SOLIDARITY_STORE,
    children: [],
  },
  {
    id: Categories.CLOTHING,
    children: [],
  },
  {
    id: Categories.ANIMAL_ASSITANCE,
    children: [],
  },
  {
    id: Categories.HEALTH,
    children: [
      Categories.ADDICTION,
      Categories.STD_TESTING,
      Categories.PSYCHOLOGICAL_SUPPORT,
      Categories.CHILD_CARE,
      Categories.GENERAL_PRACTITIONER,
      Categories.DENTAL_CARE,
      Categories.PREGNANCY_CARE,
      Categories.VACCINATION,
      Categories.INFIRMARY,
      Categories.VET_CARE,
      Categories.HEALTH_SPECIALISTS,
    ],
  },
  {
    id: Categories.ADDICTION,
    children: [],
  },
  {
    id: Categories.STD_TESTING,
    children: [],
  },
  {
    id: Categories.PSYCHOLOGICAL_SUPPORT,
    children: [],
  },
  {
    id: Categories.CHILD_CARE,
    children: [],
  },
  {
    id: Categories.GENERAL_PRACTITIONER,
    children: [],
  },
  {
    id: Categories.DENTAL_CARE,
    children: [],
  },
  {
    id: Categories.PREGNANCY_CARE,
    children: [],
  },
  {
    id: Categories.VACCINATION,
    children: [],
  },
  {
    id: Categories.INFIRMARY,
    children: [],
  },
  {
    id: Categories.VET_CARE,
    children: [],
  },
  {
    id: Categories.HEALTH_SPECIALISTS,
    children: [
      Categories.ALLERGOLOGY,
      Categories.CARDIOLOGY,
      Categories.DERMATOLOGY,
      Categories.ECHOGRAPHY,
      Categories.ENDOCRINOLOGY,
      Categories.GASTROENTEROLOGY,
      Categories.GYNECOLOGY,
      Categories.KINESITHERAPY,
      Categories.MAMMOGRAPHY,
      Categories.OPHTHALMOLOGY,
      Categories.OTORHINOLARYNGOLOGY,
      Categories.NUTRITION,
      Categories.PEDICURE,
      Categories.PHLEBOLOGY,
      Categories.PNEUMOLOGY,
      Categories.RADIOLOGY,
      Categories.RHEUMATOLOGY,
      Categories.UROLOGY,
      Categories.SPEECH_THERAPY,
      Categories.STOMATOLOGY,
      Categories.OSTEOPATHY,
      Categories.ACUPUNCTURE,
    ],
  },
  {
    id: Categories.ALLERGOLOGY,
    children: [],
  },
  {
    id: Categories.CARDIOLOGY,
    children: [],
  },
  {
    id: Categories.DERMATOLOGY,
    children: [],
  },
  {
    id: Categories.ECHOGRAPHY,
    children: [],
  },
  {
    id: Categories.ENDOCRINOLOGY,
    children: [],
  },
  {
    id: Categories.GASTROENTEROLOGY,
    children: [],
  },
  {
    id: Categories.GYNECOLOGY,
    children: [],
  },
  {
    id: Categories.KINESITHERAPY,
    children: [],
  },
  {
    id: Categories.MAMMOGRAPHY,
    children: [],
  },
  {
    id: Categories.OPHTHALMOLOGY,
    children: [],
  },
  {
    id: Categories.OTORHINOLARYNGOLOGY,
    children: [],
  },
  {
    id: Categories.NUTRITION,
    children: [],
  },
  {
    id: Categories.PEDICURE,
    children: [],
  },
  {
    id: Categories.PHLEBOLOGY,
    children: [],
  },
  {
    id: Categories.PNEUMOLOGY,
    children: [],
  },
  {
    id: Categories.RADIOLOGY,
    children: [],
  },
  {
    id: Categories.RHEUMATOLOGY,
    children: [],
  },
  {
    id: Categories.UROLOGY,
    children: [],
  },
  {
    id: Categories.SPEECH_THERAPY,
    children: [],
  },
  {
    id: Categories.STOMATOLOGY,
    children: [],
  },
  {
    id: Categories.OSTEOPATHY,
    children: [],
  },
  {
    id: Categories.ACUPUNCTURE,
    children: [],
  },
  {
    id: Categories.TECHNOLOGY,
    children: [
      Categories.COMPUTERS_AT_YOUR_DISPOSAL,
      Categories.WIFI,
      Categories.ELECTRICAL_OUTLETS_AVAILABLE,
      Categories.TELEPHONE_AT_YOUR_DISPOSAL,
      Categories.DIGITAL_SAFE,
    ],
  },
  {
    id: Categories.COMPUTERS_AT_YOUR_DISPOSAL,
    children: [],
  },
  {
    id: Categories.WIFI,
    children: [],
  },
  {
    id: Categories.ELECTRICAL_OUTLETS_AVAILABLE,
    children: [],
  },
  {
    id: Categories.TELEPHONE_AT_YOUR_DISPOSAL,
    children: [],
  },
  {
    id: Categories.DIGITAL_SAFE,
    children: [],
  },
  {
    id: Categories.MOBILITY,
    children: [
      Categories.CARPOOLING,
      Categories.PROVISION_OF_VEHICLES,
      Categories.CHAUFFEUR_DRIVEN_TRANSPORT,
      Categories.MOBILITY_ASSISTANCE,
    ],
  },
  {
    id: Categories.CARPOOLING,
    children: [],
  },
  {
    id: Categories.PROVISION_OF_VEHICLES,
    children: [],
  },
  {
    id: Categories.CHAUFFEUR_DRIVEN_TRANSPORT,
    children: [],
  },
  {
    id: Categories.MOBILITY_ASSISTANCE,
    children: [],
  },
];

// Roots are nodes not included in a node children list
export const CATEGORIES_ROOT_NODES: FlatCategoriesTreeNode[] =
  CATEGORIES.filter(
    (currentNode) =>
      typeof CATEGORIES.find((node) =>
        node.children.includes(currentNode.id)
      ) === "undefined"
  );

// Leaves are nodes with no children
export const CATEGORIES_LEAF_NODES: FlatCategoriesTreeNode[] =
  CATEGORIES.filter((currentNode) => currentNode.children.length === 0);

export const CATEGORIES_ROOTS: Categories[] = CATEGORIES_ROOT_NODES.map(
  (node) => node.id
);

export const CATEGORIES_LEAVES: Categories[] = CATEGORIES_LEAF_NODES.map(
  (node) => node.id
);
